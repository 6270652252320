.input {
    background: none;
    border: 1px solid #cccccc1a;
    width: 100%;
    height: 40px;
    padding: 10px;
    border-radius: 6px;
    color: #fff;
}

.input:focus {
    outline: none;
}
.button {
    background-color: #fff;
    width: 100%;
    height: 45px;
    border: none;
    outline: none;
    border-radius: 6px;
    font-weight:700;
    margin-top: 1rem;
}

.button:focus {
    outline: none;
}